import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

import { defineAsyncComponent } from "vue";
import Loading from "../components/Loading.vue";

const Material = defineAsyncComponent({
  loader: () => import("../views/Material.vue"),
  loadingComponent: Loading,
});
const Stock = defineAsyncComponent({
  loader: () => import("../views/Stock.vue"),
  loadingComponent: Loading,
});
const StockTotal = defineAsyncComponent({
  loader: () => import("../views/StockTotal.vue"),
  loadingComponent: Loading,
});
const StockLastTotal = defineAsyncComponent({
  loader: () => import("../views/StockLastTotal.vue"),
  loadingComponent: Loading,
});
const StockSave = defineAsyncComponent({
  loader: () => import("../views/StockSave.vue"),
  loadingComponent: Loading,
});
const SettingStockLocation = defineAsyncComponent({
  loader: () => import("../views/SettingStockLocation.vue"),
  loadingComponent: Loading,
});
const SettingFactory = defineAsyncComponent({
  loader: () => import("../views/SettingFactory.vue"),
  loadingComponent: Loading,
});
const SettingWarehouse = defineAsyncComponent({
  loader: () => import("../views/SettingWarehouse.vue"),
  loadingComponent: Loading,
});
const TransferLog = defineAsyncComponent({
  loader: () => import("../views/TransferLog.vue"),
  loadingComponent: Loading,
});
const WarehouseSupplier = defineAsyncComponent({
  loader: () => import("../views/WarehouseSupplier.vue"),
  loadingComponent: Loading,
});
const WarehousePurchaseOrder = defineAsyncComponent({
  loader: () => import("../views/WarehousePurchaseOrder.vue"),
  loadingComponent: Loading,
});

const WarehouseCheck = defineAsyncComponent({
  loader: () => import("../views/WarehouseCheck.vue"),
  loadingComponent: Loading,
});

const User = defineAsyncComponent({
  loader: () => import("../views/user/User.vue"),
  loadingComponent: Loading,
});
const Station = defineAsyncComponent({
  loader: () => import("../views/order/Station.vue"),
  loadingComponent: Loading,
});
const StationDefine = defineAsyncComponent({
  loader: () => import("../views/order/StationDefine.vue"),
  loadingComponent: Loading,
});
const Agent = defineAsyncComponent({
  loader: () => import("../views/order/Agent.vue"),
  loadingComponent: Loading,
});
const Cloth = defineAsyncComponent({
  loader: () => import("../views/order/Cloth.vue"),
  loadingComponent: Loading,
});
const CmtFactory = defineAsyncComponent({
  loader: () => import("../views/order/CmtFactory.vue"),
  loadingComponent: Loading,
});
const Customer = defineAsyncComponent({
  loader: () => import("../views/order/Customer.vue"),
  loadingComponent: Loading,
});
const Item = defineAsyncComponent({
  loader: () => import("../views/order/Item.vue"),
  loadingComponent: Loading,
});
const ProductGroup = defineAsyncComponent({
  loader: () => import("../views/order/ProductGroup.vue"),
  loadingComponent: Loading,
});
const Supplier = defineAsyncComponent({
  loader: () => import("../views/order/Supplier.vue"),
  loadingComponent: Loading,
});
const Order = defineAsyncComponent({
  loader: () => import("../views/order/Order.vue"),
  loadingComponent: Loading,
});
const SizeItem = defineAsyncComponent({
  loader: () => import("../views/order/SizeItem.vue"),
  loadingComponent: Loading,
});
const SizeType = defineAsyncComponent({
  loader: () => import("../views/order/SizeType.vue"),
  loadingComponent: Loading,
});
const StationHistory = defineAsyncComponent({
  loader: () => import("../views/order/StationHistory.vue"),
  loadingComponent: Loading,
});
const StationComplete = defineAsyncComponent({
  loader: () => import("../views/order/StationComplete.vue"),
  loadingComponent: Loading,
});
const StationWork = defineAsyncComponent({
  loader: () => import("../views/order/StationWork.vue"),
  loadingComponent: Loading,
});
const StationEstimated = defineAsyncComponent({
  loader: () => import("../views/order/StationEstimated.vue"),
  loadingComponent: Loading,
});
const StationCutOutSewNotIn = defineAsyncComponent({
  loader: () => import("../views/order/StationCutOutSewNotIn.vue"),
  loadingComponent: Loading,
});
const BoardRoom = defineAsyncComponent({
  loader: () => import("../views/order/BoardRoom.vue"),
  loadingComponent: Loading,
});
const ProcessInformationSheet = defineAsyncComponent({
  loader: () => import("../views/order/ProcessInformationSheet.vue"),
  loadingComponent: Loading,
});
const WorkHistory = defineAsyncComponent({
  loader: () => import("../views/order/WorkHistory.vue"),
  loadingComponent: Loading,
});
const Workload = defineAsyncComponent({
  loader: () => import("../views/order/Workload.vue"),
  loadingComponent: Loading,
});
const BoxTemplate = defineAsyncComponent({
  loader: () => import("../views/order/BoxTemplate.vue"),
  loadingComponent: Loading,
});
const OrderAudit = defineAsyncComponent({
  loader: () => import("../views/order/OrderAudit.vue"),
  loadingComponent: Loading,
});
const CommonTransferList = defineAsyncComponent({
  loader: () => import("../views/CommonTransferList.vue"),
  loadingComponent: Loading,
});
const Todo = defineAsyncComponent({
  loader: () => import("../views/order/Todo.vue"),
  loadingComponent: Loading,
});
const WarehouseTemplate = defineAsyncComponent({
  loader: () => import("../views/order/WarehouseTemplate.vue"),
  loadingComponent: Loading,
});
const StationProcessCombination = defineAsyncComponent({
  loader: () => import("../views/order/StationProcessCombination.vue"),
  loadingComponent: Loading,
});
const ScheduleList = defineAsyncComponent({
  loader: () => import("../views/order/ScheduleList.vue"),
  loadingComponent: Loading,
});
const ScheduleHistory = defineAsyncComponent({
  loader: () => import("../views/order/ScheduleHistory.vue"),
  loadingComponent: Loading,
});
const ScheduleListCar = defineAsyncComponent({
  loader: () => import("../views/order/ScheduleListCar.vue"),
  loadingComponent: Loading,
});
const ScheduleHistoryCar = defineAsyncComponent({
  loader: () => import("../views/order/ScheduleHistoryCar.vue"),
  loadingComponent: Loading,
});
const PurchaseOrderLineHistory = defineAsyncComponent({
  loader: () => import("../views/order/PurchaseOrderLineHistory.vue"),
  loadingComponent: Loading,
});
const PurchaseOrderLineHistorySalary = defineAsyncComponent({
  loader: () => import("../views/order/PurchaseOrderLineHistorySalary.vue"),
  loadingComponent: Loading,
});
const PurchaseOrderLineHistorySalaryLine = defineAsyncComponent({
  loader: () => import("../views/order/PurchaseOrderLineHistorySalaryLine.vue"),
  loadingComponent: Loading,
});
const PurchaseOrderLineHistoryPrice = defineAsyncComponent({
  loader: () => import("../views/order/PurchaseOrderLineHistoryPrice.vue"),
  loadingComponent: Loading,
});
const OrderDetail = defineAsyncComponent({
  loader: () => import("../views/order/OrderDetail.vue"),
  loadingComponent: Loading,
});
const OrderContentDetail = defineAsyncComponent({
  loader: () => import("../views/order/OrderContentDetail.vue"),
  loadingComponent: Loading,
});
const StockHistory = defineAsyncComponent({
  loader: () => import("../views/StockHistory.vue"),
  loadingComponent: Loading,
});
const StockGroupBy = defineAsyncComponent({
  loader: () => import("../views/StockGroupBy.vue"),
  loadingComponent: Loading,
});
const LocalEmployer = defineAsyncComponent({
  loader: () => import("../views/order/LocalEmployer.vue"),
  loadingComponent: Loading,
});

const BoardRoomEstimated = defineAsyncComponent({
  loader: () => import("../views/order/BoardRoomEstimated.vue"),
  loadingComponent: Loading,
});

const StorageAuditList = defineAsyncComponent({
  loader: () => import("../views/StorageAuditList.vue"),
  loadingComponent: Loading,
});

const Scrapped = defineAsyncComponent({
  loader: () => import("../views/order/Scrapped.vue"),
  loadingComponent: Loading,
});

const WeavingFactoryWorkOrder = defineAsyncComponent({
  loader: () => import("../views/dispatch/WeavingFactoryWorkOrder.vue"),
  loadingComponent: Loading,
});

const SpLineWorkOrder = defineAsyncComponent({
  loader: () => import("../views/dispatch/SpLineWorkOrder.vue"),
  loadingComponent: Loading,
});

const DyeingFactoryWorkOrder = defineAsyncComponent({
  loader: () => import("../views/dispatch/DyeingFactoryWorkOrder.vue"),
  loadingComponent: Loading,
});

const ColoredYarnWorkOrder = defineAsyncComponent({
  loader: () => import("../views/dispatch/ColoredYarnWorkOrder.vue"),
  loadingComponent: Loading,
});

const CollarPieceWorkOrder = defineAsyncComponent({
  loader: () => import("../views/dispatch/CollarPieceWorkOrder.vue"),
  loadingComponent: Loading,
});

const WeavingFactoryWorkOrderReport = defineAsyncComponent({
  loader: () => import("../views/dispatch/WeavingFactoryWorkOrderReport.vue"),
  loadingComponent: Loading,
});

const WeavingFactoryWorkOrderReportRecord = defineAsyncComponent({
  loader: () =>
    import("../views/dispatch/WeavingFactoryWorkOrderReportRecord.vue"),
  loadingComponent: Loading,
});

const routes = [
  {
    path: "/order-fabric/weaving-factory-work-order/record",
    name: "WeavingFactoryWorkOrderReportRecord",
    component: WeavingFactoryWorkOrderReportRecord,
  },
  {
    path: "/order-report/weaving-factory-work-order",
    name: "WeavingFactoryWorkOrderReport",
    component: WeavingFactoryWorkOrderReport,
  },
  {
    path: "/order-fabric/dyeing-factory-work-order",
    name: "DyeingFactoryWorkOrder",
    component: DyeingFactoryWorkOrder,
  },
  {
    path: "/order-fabric/sp-line",
    name: "SpLineWorkOrder",
    component: SpLineWorkOrder,
  },
  {
    path: "/order-fabric/colored-yarn",
    name: "ColoredYarnWorkOrder",
    component: ColoredYarnWorkOrder,
  },
  {
    path: "/order-fabric/collar-piece",
    name: "CollarPieceWorkOrder",
    component: CollarPieceWorkOrder,
  },
  {
    path: "/order-fabric/weaving-factory-work-order",
    name: "WeavingFactoryWorkOrder",
    component: WeavingFactoryWorkOrder,
  },
  {
    path: "/scrapped",
    name: "Scrapped",
    component: Scrapped,
  },
  {
    path: "/history-group",
    name: "StockGroupBy",
    component: StockGroupBy,
  },
  {
    path: "/history-stock",
    name: "StockHistory",
    component: StockHistory,
  },
  {
    path: "/stock",
    name: "Stock",
    component: Stock,
  },
  {
    path: "/order-content-detail",
    name: "OrderContentDetail",
    component: OrderContentDetail,
  },
  {
    path: "/order-detail",
    name: "OrderDetail",
    component: OrderDetail,
  },
  {
    path: "/orderline-price",
    name: "PurchaseOrderLineHistoryPrice",
    component: PurchaseOrderLineHistoryPrice,
  },
  {
    path: "/orderline-salary-line",
    name: "PurchaseOrderLineHistorySalaryLine",
    component: PurchaseOrderLineHistorySalaryLine,
  },
  {
    path: "/orderline-salary",
    name: "PurchaseOrderLineHistorySalary",
    component: PurchaseOrderLineHistorySalary,
  },
  {
    path: "/order-line",
    name: "PurchaseOrderLineHistory",
    component: PurchaseOrderLineHistory,
  },
  {
    path: "/schedule-history",
    name: "ScheduleHistory",
    component: ScheduleHistory,
  },
  {
    path: "/schedule-car",
    name: "ScheduleListCar",
    component: ScheduleListCar,
  },
  {
    path: "/schedule-history-car",
    name: "ScheduleHistoryCar",
    component: ScheduleHistoryCar,
  },
  {
    path: "/schedule",
    name: "ScheduleList",
    component: ScheduleList,
  },
  {
    path: "/station-combination",
    name: "StationProcessCombination",
    component: StationProcessCombination,
  },
  {
    path: "/warehouse-template",
    name: "WarehouseTemplate",
    component: WarehouseTemplate,
    children: [
      {
        path: "template", // url= shop/cart
        component: WarehouseTemplate,
        // meta: { requiresAuth: false },
      },
      {
        path: "content", // url= shop/cart
        component: WarehouseTemplate,
        // meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: "/station-define",
    name: "StationDefine",
    component: StationDefine,
  },
  {
    path: "/station-progress",
    name: "Todo",
    component: Todo,
  },
  {
    path: "/local-employer",
    name: "LocalEmployer",
    component: LocalEmployer,
  },
  {
    path: "/box-template",
    name: "BoxTemplate",
    component: BoxTemplate,
  },
  {
    path: "/work-history",
    name: "WorkHistory",
    component: WorkHistory,
  },
  {
    path: "/workload",
    name: "Workload",
    component: Workload,
  },
  {
    path: "/process",
    name: "ProcessInformationSheet",
    component: ProcessInformationSheet,
  },
  {
    path: "/warehouse-check",
    name: "WarehouseCheck",
    component: WarehouseCheck,
  },
  {
    path: "/board-room",
    name: "BoardRoom",
    component: BoardRoom,
  },
  {
    path: "/board-room-estimated",
    name: "BoardRoomEstimated",
    component: BoardRoomEstimated,
  },
  {
    path: "/station-cut",
    name: "StationCutOutSewNotIn",
    component: StationCutOutSewNotIn,
  },
  {
    path: "/station-estimated",
    name: "StationEstimated",
    component: StationEstimated,
  },
  {
    path: "/station-work",
    name: "StationWork",
    component: StationWork,
  },
  {
    path: "/station-completed",
    name: "StationComplete",
    component: StationComplete,
  },
  {
    path: "/warehouse-order",
    name: "WarehousePurchaseOrder",
    component: WarehousePurchaseOrder,
    children: [
      {
        path: "order", // url= shop/cart
        component: WarehousePurchaseOrder,
        // meta: { requiresAuth: false },
      },
      {
        path: "content", // url= shop/cart
        component: WarehousePurchaseOrder,
        // meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: "/size-item",
    name: "SizeItem",
    component: SizeItem,
  },
  {
    path: "/size-type",
    name: "SizeType",
    component: SizeType,
  },
  {
    path: "/order",
    name: "Order",
    component: Order,
  },
  {
    path: "/order-audit",
    name: "OrderAudit",
    component: OrderAudit,
  },
  {
    path: "/user",
    name: "User",
    component: User,
  },
  {
    path: "/order-station",
    name: "Station",
    component: Station,
  },
  {
    path: "/station-history",
    name: "StationHistory",
    component: StationHistory,
  },
  {
    path: "/order-agent",
    name: "Agent",
    component: Agent,
  },
  {
    path: "/order-cloth",
    name: "Cloth",
    component: Cloth,
  },
  {
    path: "/order-cmtFactory",
    name: "CmtFactory",
    component: CmtFactory,
  },
  {
    path: "/order-customer",
    name: "Customer",
    component: Customer,
  },
  {
    path: "/order-item",
    name: "Item",
    component: Item,
  },
  {
    path: "/order-product-group",
    name: "ProductGroup",
    component: ProductGroup,
  },
  {
    path: "/order-supplier",
    name: "Supplier",
    component: Supplier,
  },
  {
    path: "/",
    name: "Material",
    component: Material,
  },
  {
    path: "/total",
    name: "StockTotal",
    component: StockTotal,
  },
  {
    path: "/last-total",
    name: "StockLastTotal",
    component: StockLastTotal,
  },
  {
    path: "/stock-save",
    name: "StockSave",
    component: StockSave,
  },
  {
    path: "/setting-factory",
    name: "SettingFactory",
    component: SettingFactory,
  },
  {
    path: "/setting-stock-location",
    name: "SettingStockLocation",
    component: SettingStockLocation,
  },
  {
    path: "/setting-warehouse",
    name: "SettingWarehouse",
    component: SettingWarehouse,
  },
  {
    path: "/setting-supplier",
    name: "WarehouseSupplier",
    component: WarehouseSupplier,
  },
  {
    path: "/transfer-log",
    name: "TransferLog",
    component: TransferLog,
  },
  {
    path: "/transfer-audit",
    name: "CommonTransferList",
    component: CommonTransferList,
  },
  {
    path: "/storage-audit",
    name: "StorageAuditList",
    component: StorageAuditList,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
